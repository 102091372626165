import { computed } from 'vue';
import { useStore } from 'vuex';
import { LIST_THREADS, RETRIEVE_THREAD } from '@/store/storeTypes';
import { IThread } from '@/types';
import { CONFIG } from '@/config';

export const useNewMessages = () => {
    const store = useStore();
    const hasUnread = computed(() => store.getters.getThreads.some((thread: IThread) => thread.has_unread));

    // Threads:
    const getThreads = async () => {
        try {
            await store.dispatch(LIST_THREADS);
        } catch (error) {
            console.error(error);
        }
    };

    let threadInterval: any = null;
    const startThreadsMonitor = () => {
        // return
        threadInterval = threadInterval || setInterval(() => {
            getThreads();
        }, CONFIG.THREAD_REFRESH_INTERVAL);
        getThreads();
    };

    const clearThreadsMonitor = () => {
        clearInterval(threadInterval);
        threadInterval = null;
    };

    // Messages:
    const getMessages = async (receiver_user: number) => {
        try {
            await store.dispatch(RETRIEVE_THREAD, { receiver_user, pagination: 1 });
        } catch (error) {
            console.error(error);
        }
    };

    let newMessagesInterval: any = null;
    const startNewMessagesMonitor = (receiver_user: number) => {
        newMessagesInterval = newMessagesInterval || setInterval(() => {
            getMessages(receiver_user);
        }, CONFIG.CHAT_REFRESH_INTERVAL);
        getThreads();
    };

    const clearNewMessagesMonitor = () => {
        clearInterval(newMessagesInterval);
        newMessagesInterval = null;
    };


    return {
        hasUnread,
        startThreadsMonitor,
        clearThreadsMonitor,
        startNewMessagesMonitor,
        clearNewMessagesMonitor,
    }
};
