import { createI18n } from 'vue-i18n'
const messages = {
  bg:{
  "NewGenerationAdvertising": "Ново поколение реклама",
  "ReachTheActiveAudience": "Достигнете до активната аудитория",
  "Home":"Начало",
  "TermsOfUse":"Условия за ползване",
  "Language":"Език",
  "Menu":"Меню",
  "LogOut":"Изход",
  "Bulgarian":"Български",
  "English":"Английски",
  "Cancel":"Откажи",
  "CreateAccount": "Създаване на акаунт",
  "BeforeYouStart":"Преди да започнете",
  "YouAre":"Вие сте",
  "HouseOwner":"Собственик",
  "house_owner":"Собственик",
  "HouseKeeper":"Иконом",
  "housekeeper":"Иконом",
  "PleaseSelectAnAccountType":"Моля да изберете вида на вашия акаунт",
  "LocatedIn": "Област",
  "PleaseSelectValidDistrict": "Моля изберете опция от менюто горе",
  "PleaseSelectValidLocation": "Моля изберете локация от картата",
  "ForgotYourPassword": "Забравена парола?",
  "SignUp": "Регистрирай се",
  "LogIn": "Влез",
  "LogInToYourAccount": "Влезте в акаунта си",
  "DontHaveAnAccount": "Нямате акаунт?",
  "Password": "Парола",
  "Email": "Имейл",
  "ThisIsYou": "Това сте вие 🙂",
  "Profession": "Професия",
  "Bio": "Накратко за мен",
  "PageType": "Социална мрежа",
  "Followers": "Последователи",
  "Link": "Линк",
  "Price": "Цена за реклама(лв)",
  "Salary": "Възнаграждение за домашния помощник на месец",
  "PageDescription": "Описание на страницата",
  "AlphaNum": "Полето не трябва да съдържа само числа",
  "FirstName": "Име",
  "LastName": "Фамилия",
  "CurrentPassword": "Текуща парола",
  "NewPassword": "Нова парола",
  "ConfirmNewPassword": "Потвърдете новата парола",
  "CurrentEmail": "Текущ имейл",
  "NewEmail": "Нов имейл",
  "ConfirmNewEmail": "Потвърдете новия имейл",
  "PasswordReset": "Създаване на нова парола",
  "Send": "Изпрати",
  "Username": "Потребителско име",
  "RepeatPassword": "Повторете паролата",
  "UseDigitsAndLetters": "Използвайте цифри и букви",
  "Min8Symbols": "Минимум 8 символа.",
  "EmailSendedPleaseCheck": "Регистрацията е почти завършена. Остава само да активирате акаунта си. Моля проверете имейла си {email} там ще получите линк за активиране.",
  "ActivateAccount": "Активиране на акаунт",
  "RegistrationSuccessful": "Успешна регистрация",
  "ActivationCompleted": "Активирането на акаунта Ви е успешно. Можете да влезете в профила си.",
  "ActivationFailed": "Активирането на акаунта е неуспешно. Моля свържете се с администратор. Грешка: ",
  "RegistrationFailed": "Регистрацията е неуспешна. Грешка: ",
  "Users": "Потребители",
  "HousesInYourArea": "Къщи във вашия район",
  "Favourites": "Любими",
  "Inbox": "Съобщения",
  "PickAdPlatform": "Изберете платформа за реклама",
  "EditProfile": "Обнови профил",
  "PageDetails": "Данни за мрежата",
  "AddPage": "Добави социална мрежа",
  "Save": "Запази",
  "SaveChanges": "Запази промените",
  "DeleteAccount": "Изтриване на акаунт",
  "DeleteAccountConfirm": "Сигурни ли сте че искате да изтриете акаунта си?",
  "AccountDeleteSuccessful": "Изтрихте акаунта си успешно.",
  "RemoveListing": "Премахни обява",
  "Profile": "Профил",
  "Security": "Сигурност",
  "SecuritySettings": "Защита и вход",
  "PersonalDetails": "Общи настройки на профила",
  "ChangeEmail": "Промяна на мейл",
  "ChangePassword": "Промяна на парола",
  "ChangeAvatar": "Промяна на аватар",
  "JumpToConversation": "Търсете в съобщенията...",
  "ComposeMessage": "Напишете съобщение...",
  "Back": "Назад",
  "SendMessage": "Съобщение",
  "LogoutSuccessful": "Излязохте успешно",
  "PasswordChangedSuccessfully": "Успешно променихте паролата си",
  "EmailChangedSuccessfully": "Успешно променихте вашия имейл адрес",
  "UserChangedSuccessfully": "Успешно променихте вашите данни",
  "YouHaveNoFavourites": "Нямате любими потребители! Започнете, като плъзнете с пръст върху профил на началната страница и натиснете иконата сърце",
  "Alert": "Внимание",
  "NextActionIrreversible": "Следващото действие може да бъде безвъзвратно!",
  "YouAreTryingToDelete": "Опитвате се да изтриете чата си с потребител:",
  "DoYouWishToContinue": "Желаете ли да продължите?",
  "Delete": "Изтрий",
  "ConversationDeleted": "Успешно изтрихте този чат",
  "Contact": "Контакти",
  "ContactMessage": "Обратната връзка е важна за нас! Отворени сме към вашите идеи и препоръки! Ако имате такива не се колебайте да ни изпратите мейл. Също така, при възникнали технически проблеми или върпоси, свързани с платформата сме на разположение, за да ги решим.",
  "ContactUsAt": "Пишете ни като натиснете бутона под този текст",
  "SendUsAnEmail": "Свържете се с нас",
  "PrivacyPolicy": "Поверителност",
  "ForgottenPassword": "Забравена парола",
  "Confirm": "Потвърдете",
  "EnterEmailForPasswordReset": "Моля въведете имейла с който сте се регистрирали. На него ще получите линк за да обновите паролата си",
  "ResetPasswordEmailLink":"Изпратихме Ви имейл с линк за създаване на нова парола.",
  "Updated":"Промените са направени",
  "NoUsersOnPlatform": "Станете първият регистриран собственик на имот на нашата платформа! Регистрирайте се днес, за да си осигурите място и да започнете да използвате услугите ни.",
  "NoUsersBasedOnCriteria": "Няма намерени потребители по зададените критерии",
  "Houses": "Обекти",
  "Name": "Име",
  "Description": "Описание",
  "PropertyType": "Тип на жилището",
  "SearchByType": "Тип на имот",
  "Add": "Добави",
  "House": "Къща",
  "house": "Къща",
  "Suite": "Апартамент",
  "suite": "Апартамент",
  "Hotel": "Хотел",
  "hotel": "Хотел",
  "HouseListingTitle": "Създайте обява",
  "HouseListingDescription": "Предоставете основни данни за вашата къща, включително местоположение, тип, удобства и специални характеристики. Тази информация ще помогне на домашните помощници да разберат вашите изисквания и да вземат информирано решение при кандидатстване за позицията.",
  "AddNewHouse": "Добави нова къща",
  "ImageDeleted": "Снимката е успешно изтрита",
  "AddNewPhoto": "Добави нова снимка",
  "CreateHouseToAddImages": "Моля, запазете първоначално въведените данни, преди да добавите снимки към обявата. Щракнете върху бутона 'Запази промените', преди да продължите с добавянето на снимки.",
  "FindProperties": "Потърси имот",
  "SearchByName": "Търси по име...",
  "Theme": "Тема",
  "Light": "Светла тема",
  "Dark": "Тъмна тема",
  "Auto": "Системна",
  "PhoneNumber": "Тел. номер",
  "PhoneNumberValidation": "Полето трябва да съдържа числа или + с дължина от 9 до 15 символа",
  "HouseName": "Име на имот",
  "HouseLink": "Уебсайт",
  "HousePrice": "Цена за посещение на домашен помощник",
  "HouseDescription": "Описание",
  "UploadAnImage": "Изберете снимка",
  "UploadAnImageDescription": "За да добавите снимка към обявата, просто щракнете върху полето горе. Уверете се, че избирате атрактивна снимка, която подчертава уникалните характеристики на къщата ви.",
  "ImageSizeTooLargeMaxIs25MB": "Максималния размер на снимката трябва да е 25 мегабайта",
  "NoImageProvidedForThisHouse": "Няма изображения за тази къща",
  "PerVisit": "на посещение",
  "VisitWebsite": "Посети уебсайт",

  "validations": {
    "required": "Полето е задължително",
    "numeric": "Полето трябва да бъде число",
    "url": "Невалиден линк",
    "email": "Невалидна ел.поща",
    "minLength": "Полето трябва да бъде поне {min} символа",
    "maxLength": "Полето не трябва да бъде повече от {max} символа",
    "sameAsPassword": "Паролите не съвпадат",
  },

  "districts": {
    "PickALocation": "Избери област",
    "SofiaCity": "София - Град",
    "SofiaDistrict": "София - Област",
    "Varna": "Варна",
    "Plovdiv": "Пловдив",
    "Burgas": "Бургас",
    "Ruse": "Русе",
    "StaraZagora": "Стара Загора",
    "Dobrich": "Добрич",
    "Pazardzhik": "Пазарджик",
    "Shumen": "Шумен",
    "Pernik": "Перник",
    "Yambol": "Ямбол",
    "Kardzhali": "Кърждали",
    "Kyustendil": "Кюстендил",
    "Blagoevgrad": "Благоевград",
    "Sliven": "Сливен",
    "Gabrovo": "Габрово",
    "VelikoTarnovo": "Велико Търново",
    "Vidin": "Видин",
    "Vratsa": "Враца",
    "Razgrad": "Разград",
    "Montana": "Монтана",
    "Lovech": "Ловеч",
    "Haskovo": "Хасково",
    "Smolyan": "Смолян",
    "Targovishte": "Търговище",
    "Silistra": "Силистра",
  },

  "onboarding": [
    {
      "title": "Добре дошли!",
      "description": "Открийте и се свържете с домакини или домашни помощници за вашите ваканционни имоти в България.",
      "options": [
        { "text": "Напред", "action": "next" }
      ]
    },
    {
      "title": "Вие сте домакин?",
      "description": "Регистрирайте се като домакин и намерете подходящи домашни помощници.",
      "options": [
        { "text": "Напред", "action": "next" }
      ]
    },
    {
      "title": "Регистрирайте се като домашен помощник",
      "description": "Ако търсите работа като домашен помощник за ваканционни имоти, регистрирайте се и се свържете с нашите домакини.",
      "options": [
        { "text": "Напред", "action": "next" }
      ]
    },
    {
      "title": "Ready to Play?",
      "description": " Регистрирайте се и започнете да търсите, или просто прегледайте свободните обяви за ваканционни имоти.",
      "options": [
        { "text": "Домакин", "action": "house_owner" },
        { "text": "Домашен помощник", "action": "housekeeper" },
        { "text": "Виж обявите", "action": "see_homes" },
      ]
    }
  ],

  // translations from djoser
  "Userwiththisemailaddressalreadyexists": "Потребител с такъв имейл вече съществува.",
  "Thepasswordistoosimilartotheusername": "Паролата е сходна с потребителското име.",
  "Thepasswordistoosimilartotheemailaddress": "Паролата е сходна с имейла.",
  "Thispasswordistoocommon": "Паролата е често срещана.",
  "Thetwopasswordfieldsdidntmatch": "Паролите не съвпадат.",
  "Invalid password": "Невалидна парола.",
  "Unabletologinwithprovidedcredentials": "Няма потребител с такъв имейл или парола.",

  "потребителстозиemailaddressвечесъществува": "Потребител с такъв имейл вече съществува.",
  "потребителстозиusernameвечесъществува": "Потребител с такова потребителско име вече съществува.",
  "Паролатаемногоподобнанаusername": "Паролата е сходна с потребителското име.",
  "Паролатаемногоподобнанаemailaddress": "Паролата е сходна с имейла.",
},
 en:{
  "NewGenerationAdvertising": "Grow your brand",
  "ReachTheActiveAudience": "Reach the active audience",
  "Home":"Home",
  "TermsOfUse":"Privary and Terms of use",
  "Language":"Language",
  "Menu":"Menu",
  "LogOut":"Log out",
  "Bulgarian":"Bulgarian",
  "English":"English",
  "Cancel":"Cancel",
  "CreateAccount":"Create account",
  "BeforeYouStart":"Before you start",
  "YouAre":"You are",
  "HouseOwner":"House owner",
  "house_owner":"House owner",
  "HouseKeeper":"Housekeeper",
  "housekeeper":"Housekeeper",
  "PleaseSelectAnAccountType":"Please select your account type",
  "LocatedIn": "District",
  "PleaseSelectValidDistrict": "Please select an option from the menu above",
  "PleaseSelectValidLocation": "Please select a location from the map above",
  "ForgotYourPassword":"Forgot your password?",
  "SignUp":"Sign up",
  "LogIn":"Log in",
  "LogInToYourAccount": "Log in to your account",
  "DontHaveAnAccount":"Don't have an account?",
  "Password": "Password",
  "Email": "Email",
  "NewEmail": "New email",
  "ConfirmNewEmail": "Confirm new email",
  "ThisIsYou": "This is you 🙂",
  "Profession": "Profession",
  "Bio": "Bio",
  "Followers": "Followers",
  "Link": "Link",
  "Price": "Price(lv)",
  "Salary": "Stipend for domestic helper per month",
  "PageDescription": "Page description",
  "AlphaNum": "The field musn't contain only integers",
  "FirstName": "First name",
  "LastName": "Last name",
  "CurrentPassword": "Current password",
  "NewPassword": "New password",
  "ConfirmNewPassword": "Confirm new password",
  "CurrentEmail": "Current email",
  "PasswordReset": "Password reset",
  "Send": "Send",
  "Username": "Username",
  "RepeatPassword": "Repeat password",
  "UseDigitsAndLetters": "Use digits and letters",
  "Min8Symbols": "Minimum 8 symbols",
  "EmailSendedPleaseCheck": "Registration is almost complete. All that remains is to activate your account. We have sent you an email to {email} with an activation link.",
  "ActivateAccount": "Activate account",
  "RegistrationSuccessful": "Registration successful",
  "ActivationCompleted": "Activation is successful. You can log in now.",
  "ActivationFailed": "Activation failed. Please contact with support. Error: ",
  "RegistrationFailed": "Registration failed. Error: ",
  "Users": "Users",
  "HousesInYourArea": "Houses in your area",
  "Favourites": "Favourites",
  "Inbox": "Inbox",
  "PickAdPlatform": "Pick a platform to advertise",
  "EditProfile": "Edit profile",
  "PageDetails": "Page Details",
  "AddPage": "Add page",
  "Save": "Save",
  "SaveChanges": "Save changes",
  "DeleteAccount": "Delete account",
  "DeleteAccountConfirm": "Are you sure that you want to delete your account?",
  "AccountDeleteSuccessful": "Account deletion is successful.",
  "RemoveListing": "Remove listing",
  "Profile": "Profile",
  "Security": "Security",
  "SecuritySettings": "Security Settings",
  "PersonalDetails": "Personal Details",
  "ChangeEmail": "Change Email",
  "ChangePassword": "Change Password",
  "ChangeAvatar": "Change Avatar",
  "JumpToConversation": "Jump to conversation...",
  "ComposeMessage": "Compose message...",
  "Back": "Back",
  "SendMessage": "Send message",
  "LogoutSuccessful": "You've been logged out",
  "PasswordChangedSuccessfully": "You've changed your password successfully",
  "EmailChangedSuccessfully": "You've changed your email successfully",
  "UserChangedSuccessfully": "You've changed your details successfully",
  "YouHaveNoFavourites": "You have no favourites! Add some by swiping left on a user and clicking the hearth!",
  "Alert": "Alert",
  "NextActionIrreversible": "Your next action may be irreversible!",
  "YouAreTryingToDelete": "You are trying to delete conversation with user:",
  "DoYouWishToContinue": "Do you wish to continue?",
  "Delete": "Delete",
  "ConversationDeleted": "Conversation successfully deleted",
  "Contact": "Contact",
  "ContactMessage": "Your feedback is important for us! We are open to suggestions and recommendations, so don't hesitate to contact us! Your feedback is important for us! We are open to suggestions and recommendations, so don't hesitate to contact us! Should you encounter technical difficulties or have any questions about the platform, we are here to help.",
  "ContactUsAt": "Email us by clicking the button below",
  "SendUsAnEmail": "Contact us by email",
  "PrivacyPolicy": "Privacy policy",
  "ForgottenPassword": "Forgotten password",
  "Confirm": "Confirm",
  "EnterEmailForPasswordReset": "Pleace enter your email address so we can send you link to reset you forgotten password",
  "ResetPasswordEmailLink":"Check your email for a link and follow it to reset create new password.",
  "Updated":"Updated",
  "NoUsersOnPlatform": "Be the first registered home owner on our platform! Sign up today to claim your spot and start exploring our services.",
  "NoUsersBasedOnCriteria": "No users found based on search criteria",
  "Houses": "Properties",
  "Name": "Name",
  "Description": "Description",
  "PropertyType": "Property type",
  "SearchByType": "Search by type",
  "Add": "Add",
  "House": "House",
  "house": "House",
  "Suite": "Suite",
  "suite": "Suite",
  "Hotel": "Hotel",
  "hotel": "Hotel",
  "HouseListingTitle": "Create a listing",
  "HouseListingDescription": "Provide essential details about your house, including its location, type, amenities, and special features. This information will help housekeepers understand your requirements and make informed decisions when applying for the position.",
  "AddNewHouse": "Add new house",
  "ImageDeleted": "Image is deleted",
  "AddNewPhoto": "Add new photo",
  "CreateHouseToAddImages": "Please save the initial details of your property before adding photos to the listing. Click the 'Save changes' button to ensure that your property information is stored before proceeding with adding photos.",
  "FindProperties": "Find Properties",
  "SearchByName": "Search by name...",
  "Theme": "Theme",
  "Light": "Light theme",
  "Dark": "Dark theme",
  "Auto": "System",
  "PhoneNumber": "Phone number",
  "PhoneNumberValidation": "Only numbers or + with length from 9 to 15 symbols",
  "HouseName": "Property name",
  "HouseLink": "Website URL",
  "HousePrice": "Payment per visit",
  "HouseDescription": "Property description",
  "UploadAnImage": "Upload an image",
  "UploadAnImageDescription": "To add an image to the listing, simply click on the box above. Make sure to choose an appealing image that highlights the unique features of your house.",
  "ImageSizeTooLargeMaxIs25MB": "Max size of image must be up to 25 MB",
  "NoImageProvidedForThisHouse": "No image provided for this house",
  "PerVisit": "per visit",
  "VisitWebsite": "Visit website",

  "validations": {
    "required": "Required field",
    "numeric": "Value must be numeric",
    "url": "Not valid url",
    "email": "Not valid email",
    "minLength": "Value must be at least {lenght} charecters long",
    "maxLength": "The field must not be more than {max} characters",
    "sameAsPassword": "Passwords doesn't match",
  },

  "districts": {
    "PickALocation": "Pick a location",
    "SofiaCity": "Sofia - City",
    "SofiaDistrict": "Sofia - District",
    "Varna": "Varna",
    "Plovdiv": "Plovdiv",
    "Burgas": "Burgas",
    "Ruse": "Ruse",
    "StaraZagora": "Stara Zagora",
    "Dobrich": "Dobrich",
    "Pazardzhik": "Pazardzhik",
    "Shumen": "Shumen",
    "Pernik": "Pernik",
    "Yambol": "Yambol",
    "Kardzhali": "Kardzhali",
    "Kyustendil": "Kyustendil",
    "Blagoevgrad": "Blagoevgrad",
    "Sliven": "Sliven",
    "Gabrovo": "Gabrovo",
    "VelikoTarnovo": "Veliko Tarnovo",
    "Vidin": "Vidin",
    "Vratsa": "Vratsa",
    "Razgrad": "Razgrad",
    "Montana": "Montana",
    "Lovech": "Lovech",
    "Haskovo": "Haskovo",
    "Smolyan": "Smolyan",
    "Targovishte": "Targovishte",
    "Silistra": "Silistra",
  },
  
  "onboarding": [
    {
      "title": "Welcome!",
      "description": "Discover and connect with house owners or housekeepers for your vacation properties in Bulgaria.",
      "options": [
        { "text": "Next", "action": "next" }
      ]
    },
    {
      "title": "Register as a house owner",
      "description": "If you have properties that you offer for accommodation, register as a house owner and find suitable housekeepers",
      "options": [
        { "text": "Next", "action": "next" }
      ]
    },
    {
      "title": "Register as a housekeeper",
      "description": "If you are looking for work as a housekeeper for vacation properties, register and connect with house owners.",
      "options": [
        { "text": "Next", "action": "next" }
      ]
    },
    {
      "title": "Register or just browse",
      "description": " Choose the option that best suits you.",
      "options": [
        { "text": "House Owner", "action": "house_owner" },
        { "text": "Housekeeper", "action": "housekeeper" },
        { "text": "See homes", "action": "see_homes" },
      ]
    }
  ],

  
  // translations from djoser
  // translations must be whole error message comming from djoser, without spaces
  "Userwiththisemailaddressalreadyexists": "User with this email address already exists.",
  "Thepasswordistoosimilartotheusername": "The password is too similar to the username.",
  "Thepasswordistoosimilartotheemailaddress": "The password is too similar to the email.",
  "Thispasswordistoocommon": "This password is too common.",
  "Thetwopasswordfieldsdidntmatch": "The two password fields didn't match.",
  "Unabletologinwithprovidedcredentials": "Unable to log in with provided credentials.",
  "Invalid password": "Invalid password.",
}};

export default createI18n({
  legacy: false,
  globalInjection: true,
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages

})