// Types:
import {
  INIT_USER,
  SET_USER,
  ADD_FAVOURITE_USER,
  ADD_FAVOURITE,
  REMOVE_FAVOURITE_USER,
  REMOVE_FAVOURITE,
  PURGE_USER,
  PURGE_AUTH,
  SET_USER_LANGUAGE,
  SET_USER_THEME,
  CHANGE_USER_THEME,
  SET_DISTRICTS,
  FETCH_DISTRICTS,
  SET_ONBOARDING_STATUS,
  ONBOARDING_STATUS,
  COMPLETE_ONBOARDING,
  IComposeAction,
} from "./storeTypes";
import { ICommonState, IDistrict, IFavourite, IUser } from '@/types';

// Services:
import { getDistricts } from "@/services/common";
import { getAuthenticatedUser } from "@/services/user";
import { createFavourite, deleteFavourite } from "@/services/favourites";

// Composables:
import { useLocalStorage } from "@/composables/localStorage";

// Vue:
import i18n from '@/i18n';
import router from '@/router';

const localSorage = useLocalStorage();
const state: ICommonState = {
  user: <IUser>{},
  districts: [],
  favourites: [],
  theme: null,
  onboarding: false,
  selectedDistrict: 0,
};

const getters = {
  getCurrentUser(state: ICommonState) {
    return state.user;
  },

  getFavourites(state: ICommonState) {
    return state.user.favourites;
  },

  getDistricts(state: ICommonState) {
    return state.districts;
  },

  getAuthenticatedUserProfile(state: ICommonState): IUser {
    return state.user;
  },

  getCurrentTheme(state: ICommonState): ICommonState['theme'] {
    return state.theme;
  }
};

const mutations = {
  [SET_DISTRICTS](state: ICommonState, districts: IDistrict[]) {
    state.districts = districts;
  },
  [ADD_FAVOURITE](state: ICommonState, favourite: IFavourite) {
    state.user.favourites = state.user.favourites.concat(favourite);
  },
  [REMOVE_FAVOURITE](state: ICommonState, favouriteId: number) {
    state.user.favourites = state.user.favourites
      .filter(({ favourite_id }: IFavourite) => favourite_id !== favouriteId);
  },
  [SET_USER](state: ICommonState, user: IUser) {
    state.user = user;
  },
  [SET_USER_THEME](state: ICommonState, theme: 'light' | 'dark') {
    state.theme = theme;
  },
  [PURGE_USER](state: ICommonState) {
    state.user = <IUser>{};
  },
  [SET_ONBOARDING_STATUS](state: ICommonState, isOnboardingComplete: boolean) {
    state.onboarding = isOnboardingComplete;
  }
};

const actions = {
  async [SET_USER_LANGUAGE]() {
    const languageFromStorage = await localSorage.get('language');// if not sotrage get defaults value

    if (languageFromStorage != null && i18n.global.locale.value != languageFromStorage) {
      i18n.global.locale.value = languageFromStorage;
    }
  },
  async [CHANGE_USER_THEME]({ commit }: any, selectedTheme: string) {
    await localSorage.set('theme', selectedTheme);
    commit(SET_USER_THEME, selectedTheme);

  },
  async [FETCH_DISTRICTS]({ commit }: IComposeAction<ICommonState>) {
    const districts: IDistrict[] = await getDistricts();
    commit(SET_DISTRICTS, districts);
  },
  async [INIT_USER]({ commit, dispatch }: any) {
    try {
      const authenticatedUser = await getAuthenticatedUser();
      commit(SET_USER, authenticatedUser);
      // Language:
      await dispatch(SET_USER_LANGUAGE);
      // Onboarding:
      const onboardingStatus = await localSorage.get(ONBOARDING_STATUS);
      if (onboardingStatus) {
        commit(SET_ONBOARDING_STATUS, true);
      }

    } catch (error) {
      commit(PURGE_AUTH, null, { root: true })
      router.push({ path: '/login' })
      console.error('Error INIT_USER ${error}');
    }
  },
  async [ADD_FAVOURITE_USER]({ commit }: any, payload: FormData) {
    try {
      const favourite: IFavourite = await createFavourite(payload);
      commit(ADD_FAVOURITE, favourite);
    } catch (error) {
      console.error('Error found in ADD_FAVOURITE_USER ', error);
    }
  },
  async [REMOVE_FAVOURITE_USER]({ commit }: any, favouriteId: number) {
    try {
      await deleteFavourite(favouriteId);
      commit(REMOVE_FAVOURITE, favouriteId);
    } catch (error) {
      console.error('Error found in REMOVE_FAVOURITE_USER ', error);
    }
  },
  async [COMPLETE_ONBOARDING]({ commit }: any) {
    await localSorage.set(ONBOARDING_STATUS, true);
    commit(SET_ONBOARDING_STATUS, true);
  }
}

const store = {
  state,
  getters,
  mutations,
  actions
};
export default store;