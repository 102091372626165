import { runFetch } from './request';
import { IThread, IMessage, IResponseMessage } from '@/types';

export async function listThreads(): Promise<IThread[]> {
  const result = await runFetch('chat/', { method: 'GET', redirect: 'follow' }, true);

  return result;
}

export async function retrieveThread(receiverID: number, pagination: number): Promise<IMessage[]> {
  const result = await runFetch(`chat/retrieve/?p=${pagination}&receiver=${receiverID}`, {method: 'GET'}, true);

  return result;
}

export async function patchThreadRead(receiverID: number): Promise<IMessage[]> {
  const result = await runFetch(`chat/retrieve/?receiver=${receiverID}`, {method: 'PATCH'}, true);

  return result;
}

export async function deleteThread(form: FormData): Promise<IResponseMessage> {
  const result = await runFetch('chat/delete/', {method: 'POST', body: form}, true);

  return result;
}

export async function createMessage(form:FormData): Promise<IMessage> {
  const result = await runFetch(`chat/send/`, { body: form,method: 'POST'}, true);

  return result;
}