import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b443a76"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_avatar = _resolveComponent("ion-avatar")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_menu = _resolveComponent("ion-menu")!
  const _component_ion_split_pane = _resolveComponent("ion-split-pane")!

  return (_openBlock(), _createBlock(_component_ion_split_pane, {
    when: "md",
    contentId: "main"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_menu, {
        ref: "menuRef",
        "content-id": "main",
        type: "push"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_header, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, null, {
                default: _withCtx(() => [
                  (_ctx.isLoggedIn)
                    ? (_openBlock(), _createBlock(_component_ion_card, {
                        key: 0,
                        class: "wrapper-card ion-no-padding"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_avatar, { onClick: _ctx.pushToUserProfile }, {
                            default: _withCtx(() => [
                              (_ctx.user.avatar)
                                ? (_openBlock(), _createBlock(_component_ion_img, {
                                    key: 0,
                                    alt: "User avatar",
                                    src: _ctx.user.avatar
                                  }, null, 8, ["src"]))
                                : (_openBlock(), _createBlock(_component_ion_icon, {
                                    key: 1,
                                    icon: _ctx.personCircleOutline,
                                    class: "message-avatar",
                                    color: "primary"
                                  }, null, 8, ["icon"]))
                            ]),
                            _: 1
                          }, 8, ["onClick"]),
                          _createVNode(_component_ion_card_header, {
                            onClick: _ctx.pushToUserProfile,
                            class: "ion-no-padding"
                          }, {
                            default: _withCtx(() => [
                              (_ctx.user.first_name)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                                    _createVNode(_component_ion_card_title, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.getName(_ctx.user)), 1)
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_ion_card_subtitle, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode("@" + _toDisplayString(_ctx.user.username), 1)
                                      ]),
                                      _: 1
                                    })
                                  ]))
                                : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                                    _createVNode(_component_ion_card_title, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.user.username), 1)
                                      ]),
                                      _: 1
                                    })
                                  ]))
                            ]),
                            _: 1
                          }, 8, ["onClick"]),
                          _createVNode(_component_ion_card_content, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_button, {
                                slot: "end",
                                fill: "outline",
                                size: "small",
                                color: "primary",
                                class: "ion-no-margin ion-margin-top",
                                button: "",
                                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.userLogout()))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('LogOut')), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : (_openBlock(), _createBlock(_component_ion_item, {
                        key: 1,
                        lines: "none",
                        button: "",
                        onClick: _ctx.handleLogInClick,
                        detail: true,
                        "detail-icon": _ctx.personCircleOutline
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, { class: "ion-padding-vertical" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.t("LogInToYourAccount")), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["onClick", "detail-icon"]))
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_content, {
            class: "ion-padding",
            "scroll-y": false
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_list, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_item, {
                    lines: "none",
                    class: "menu-item ion-margin-bottom"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_select, {
                        onIonChange: _ctx.changeLanguage,
                        value: _ctx.locale,
                        placeholder: _ctx.t('Language'),
                        "cancel-text": _ctx.t('Cancel')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_select_option, { value: "bg" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.t('Bulgarian')), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_select_option, { value: "en" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.t('English')), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["onIonChange", "value", "placeholder", "cancel-text"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, {
                    lines: "none",
                    class: "menu-item ion-margin-bottom",
                    detail: false
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        icon: _ctx.currentThemeIcon,
                        size: "small",
                        slot: "end"
                      }, null, 8, ["icon"]),
                      _createVNode(_component_ion_select, {
                        slot: "start",
                        onIonChange: _ctx.changeTheme,
                        value: _ctx.currentThemeLabel.toLocaleLowerCase(),
                        placeholder: _ctx.t(_ctx.currentThemeLabel),
                        "cancel-text": _ctx.t('Cancel')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_select_option, { value: "light" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.t('Light')), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_select_option, { value: "dark" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.t('Dark')), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["onIonChange", "value", "placeholder", "cancel-text"])
                    ]),
                    _: 1
                  }),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (page, index) => {
                    return (_openBlock(), _createBlock(_component_ion_item, {
                      key: index,
                      lines: "none",
                      button: "",
                      detail: "",
                      class: "menu-item ion-margin-bottom",
                      onClick: ($event: any) => (_ctx.openPage(page.details.url))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, { class: "ion-padding-vertical" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t(page.details.name)), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["onClick"]))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 512),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _: 3
  }))
}