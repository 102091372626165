import { changePassword, changeProfile, changeEmail, resetPassword,resetPasswordSendEmail, userActivate,postRegister } from "@/services/privacyChange";
import { postLogin } from "@/services/postLogin";
import { ILoginCompleted } from '@/types';
import {useLocalStorage} from "@/composables/localStorage"
import {
  // - Actions    
  LOGIN,
  LOGOUT,
  REGISTER,
  CHANGE_PASSWORD,
  CHANGE_PROFILE,
  CHANGE_EMAIL,
  RESET_PASSWORD_SEND_EMAIL,
  RESET_PASSWORD,
  USER_ACTIVATE,
  FETCH_ACCESS_TOKEN,
  INIT_USER,

  // - Mutations
  SET_AUTH,
  SET_TOKEN,
  PURGE_AUTH,
  SET_ERROR,
  PURGE_USER,
  PURGE_CHAT,
  // SET_USER, 

  // - Interfaces
  IComposeAction,
  AUTH_TOKEN
} from "./storeTypes";
import { removeAuthHeaders, setAuthHeaders } from "@/services/request";

export interface IAuthState {
  errors: string[]
  token: string
  isAuthenticated: boolean
}

const storage = useLocalStorage();

const state: IAuthState = {
  errors: [],
  token: '',
  isAuthenticated: false
};

const getters = {
  getErrors(state: IAuthState) {
    return state.errors;
  },
  token(state: IAuthState) {
    return state.token;
  },
  // currentUser(state: any) {
  //   return state.user;
  // },
  isAuthenticated(state: any) {
    return state.isAuthenticated;
  }
};

const mutations = {
  [SET_ERROR](state: IAuthState, errors: string[]) {
    state.errors = errors;
  },
  [SET_TOKEN](state: any, token: string) {
    //TODO add telemetry console.log('✅ (state) Token recieved!', response);
    if(!token) {
      state.token = null;
      return
    }
    state.token = token;
  },
  [SET_AUTH](state: any) {
    state.errors = [];
    state.isAuthenticated = true;
  },
  [PURGE_AUTH](state: any) {
    storage.remove(AUTH_TOKEN);
    //state.user = {};
    state.errors = [];
    state.token = '';
    state.isAuthenticated = false;
  }
};

const actions = {
  async [LOGIN]({ commit, dispatch }: IComposeAction<IAuthState>, credentials: FormData) {
    try {
      // AUTH:
      const response: ILoginCompleted = await postLogin(credentials);
      await storage.set(AUTH_TOKEN, response.auth_token);
      commit(SET_TOKEN, response.auth_token);
      setAuthHeaders(response.auth_token);

      await dispatch(INIT_USER, null, { root: true }) 
      commit(SET_AUTH);

    } catch (error) {
      commit(SET_ERROR, error);
    }
  },
  async [FETCH_ACCESS_TOKEN]({ commit, dispatch }: IComposeAction<IAuthState>) {
    const token = await storage.get(AUTH_TOKEN);
    if(token){
      commit(SET_TOKEN, token);
      setAuthHeaders(token);
      await dispatch(INIT_USER, null, { root: true });
      commit(SET_AUTH);
    }
    else
      commit(SET_TOKEN, null);
  },
  async [LOGOUT]({commit}:any) {
    commit(PURGE_USER, null, { root: true }) 
    commit(PURGE_CHAT, null, { root: true }) 
    commit(PURGE_AUTH);

    removeAuthHeaders();
  },
  async [REGISTER]({ commit }: IComposeAction<IAuthState>, userDetails: FormData) {
    try {

      await postRegister(userDetails);
      return true;
    } catch (error) {
      console.error('Error found in REGISTER', error);
      commit(SET_ERROR, error);
      return false;
    }
  },
  async [CHANGE_PASSWORD]({ commit }: IComposeAction<IAuthState>, userPrivacy: FormData) {
    try {
      await changePassword(userPrivacy);
      return true;
    } catch (error) {
      console.error('Error found in CHANGE_PASSWORD', error);
      commit(SET_ERROR, error);
      return false;
    }
  },
  async [CHANGE_PROFILE]({ commit }: IComposeAction<IAuthState>, userPrivacy: FormData) {
    try {
      await changeProfile(userPrivacy);
      return true;
    } catch (error) {
      console.error('Error found in CHANGE_PROFILE', error);
      commit(SET_ERROR, error);
      return false;
    }
  },
  async [CHANGE_EMAIL]({ commit }: IComposeAction<IAuthState>, userPrivacy: FormData) {
    try {
      await changeEmail(userPrivacy);
      return true;
    } catch (error) {
      console.error('Error found in changeEmail', error);
      commit(SET_ERROR, error);
      return false;
    }
  },
  async [RESET_PASSWORD_SEND_EMAIL]({ commit }: IComposeAction<IAuthState>, email: FormData) {
    try {
      await resetPasswordSendEmail(email);
      return true;
    } catch (error) {
      console.error('Error found in resetPassword send email', error);
      commit(SET_ERROR, error);
      return false;
    }
  },
  async [RESET_PASSWORD]({ commit }: IComposeAction<IAuthState>, userPrivacy: FormData) {
    try {
      commit(SET_ERROR, []);
      await resetPassword(userPrivacy);
    } catch (error) {
      console.error('Error found in resetPassword', error);
      commit(SET_ERROR, error);
    }
  },
  async [USER_ACTIVATE]({ commit }: IComposeAction<IAuthState>, activateUserData: FormData) {
    try {
      commit(SET_ERROR, []);
      await userActivate(activateUserData);
      return { isOk: true, errorMessage: ""};
    } catch (error) {
      console.error('Error found in userActivate', error);
      commit(SET_ERROR, error);
      return { isOk: false, errorMessage: error};
    }
  },
};

const store = {
  state,
  actions,
  mutations,
  getters
};

export default store;