import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_tab_button = _resolveComponent("ion-tab-button")!
  const _component_ion_tab_bar = _resolveComponent("ion-tab-bar")!
  const _component_ion_tabs = _resolveComponent("ion-tabs")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, { "scroll-y": false }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_tabs, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_router_outlet, { class: "app-view" }),
              _createVNode(_component_ion_tab_bar, {
                id: "ionTabBar",
                slot: "bottom"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_tab_button, {
                    class: "tab-button",
                    tab: "details",
                    href: "/tabs/home"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        class: "tab-icon",
                        icon: _ctx.searchOutline
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }),
                  (_ctx.isLoggedIn)
                    ? (_openBlock(), _createBlock(_component_ion_tab_button, {
                        key: 0,
                        class: "tab-button",
                        tab: "favourites",
                        href: "/tabs/favourites"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            class: "tab-icon",
                            icon: _ctx.heartOutline
                          }, null, 8, ["icon"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.isLoggedIn)
                    ? (_openBlock(), _createBlock(_component_ion_tab_button, {
                        key: 1,
                        class: "tab-button",
                        tab: "messages",
                        href: "/tabs/messages"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            class: _normalizeClass({ 'tab-icon': true, 'primary-tint': _ctx.hasUnread }),
                            icon: _ctx.hasUnread ? _ctx.mailUnreadOutline : _ctx.mailOutline
                          }, null, 8, ["class", "icon"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_ion_tab_button, {
                    class: "tab-button",
                    tab: "userProfile",
                    href: "/tabs/userProfile"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        class: "tab-icon",
                        icon: _ctx.personOutline
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}