import i18n from '../i18n';
import { handleErrors } from "@/composables/handleErrors";

const { t, te } = i18n.global;
export function useErrorMessageComposable() {
    const { openToast } = handleErrors();

    function toastErrorMessage(error: string) {
        error = error.toString();
        const errorChars = error.toString().split(' ').join('');
        const msgLength = errorChars.length;
        const errorMsg = errorChars.substring(0, msgLength - 1).replace(/["']/g, "");
        
        const isDot = error.substring(error.length,error.length -1) == '.' ? true : false;
        if(isDot){
            error = error.substring(0,error.length - 1);
        }
        
        //check if translation exists
        if (te(error)) {
            openToast(t(error), 'danger', 5000);
        } else if (te(errorMsg)) {
            openToast(t(errorMsg), 'danger', 5000);
        } else {
            openToast(error, 'danger', 5000);
        }
    }

    return {
        toastErrorMessage
    }
}