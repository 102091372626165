import { IUser } from "@/types";

/**
 * Allows to retrieve the correct user name
 */
export function handleUserDetails() {
    // Open modal:
    function getName(user: IUser): string {
        return user.first_name
            ? `${user.first_name} ${user.last_name}`
            : user.username;
    }

    return { getName };
}