import { runFetch } from './request';
import { IUsersWithPagination, IUser, Response } from '@/types';

/**
 * List ALL Users with pagination
 * @route GET /api/v1/cusers/
 * @param {number} page - Used for paginating results
 */
export async function getAllUsersWithPagination(page: number): Promise<IUsersWithPagination> {
  const result: IUsersWithPagination = await runFetch(`cusers/?page=${page}`);
  return result;
}

/**
 * List Users by specific IDs
 * @route GET /api/v1/cusers?ids=:ids
 * @param {string} ids Comma separated IDs, i.e. '3,4,5'
 */
export async function getUsersByIds(ids: string) {
  const result = await runFetch(`cusers/?ids=${ids}`);
  return result;
}

/**
 * Retrieve SINGLE User by ID
 * @route GET /api/v1/cuser/:id/
 * @param {number} id - The ID of the user
 */
export async function getUserById(id: number): Promise<IUser> {
  const result: IUser = await runFetch(`cuser/${id}/`);
  return result;
}

/**
 * Get AUTHENTICATED User
 * @route GET /api/v1/users/me/
 */
export async function getAuthenticatedUser(): Promise<IUser> {
  const result: IUser = await runFetch(`users/me/`, { method: 'GET' }, true);
  return result;
}

/**
 * Update AUTHENTICATED User
 * @route GET /api/v1/cuser/update/
 * @param {FormData} form 
 */
export async function updateUser(form: FormData): Promise<Response<IUser>> {
  const result = await runFetch('cuser/update/', { method: 'PATCH', body: form }, true);
  return result;
}

export async function searchUsers(queryParameters: any) {
  const params: any = Object.keys(queryParameters)
    .filter(param => queryParameters[param])
    .map(param => `${param}=${queryParameters[param]}`).join('&');

  const result = await runFetch(`search/users/?${params}`);
  return result;
}
