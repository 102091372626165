import { runFetch } from './request';
import i18n from '@/i18n';
import { CONFIG } from '@/config';

export async function changePassword(form: FormData): Promise<object> {
  const result = await runFetch('users/set_password/', {method: 'POST', redirect: 'follow', body: form}, true);

  return result;
}

export async function changeProfile(form: FormData): Promise<object> {
  const result = await runFetch('users/me/update/', {method: 'PATCH', redirect: 'follow', body: form}, true);

  return result;
}

export async function changeEmail(form: FormData): Promise<object> {
  const result = await runFetch('users/set_email/', {method: 'POST', redirect: 'follow', body: form}, true);

  return result;
}

export async function resetPasswordSendEmail(email: FormData): Promise<object> {
  const url = `${CONFIG.API_HOST}${i18n.global.locale.value}/${CONFIG.API_URL}users/reset_password/`;

  const result = await runFetch("", {method: 'POST', redirect: 'follow', body: email },false,url);
  return result;
}

export async function resetPassword(form: FormData): Promise<object> {
  const url = `${CONFIG.API_HOST}${i18n.global.locale.value}/${CONFIG.API_URL}users/reset_password_confirm/`;

  const result = await runFetch("", {method: 'POST', redirect: 'follow', body: form }, false, url);
  return result;
}

export async function userActivate(form: FormData): Promise<object> {
  const result = await runFetch(`users/activation/`, {method: 'POST', redirect: 'follow', body: form });

  return result;
}

export async function postRegister(form: FormData): Promise<object> {
  const url = `${CONFIG.API_HOST}${i18n.global.locale.value}/${CONFIG.API_URL}users/`;
  const result = await runFetch("", {method: 'POST', redirect: 'follow', body: form},false, url);

  return result;
}

export async function deleteUserAccount(form: FormData): Promise<object> {
  const result = await runFetch('users/me/', { method: 'DELETE', body: form }, true);
  return result;
}