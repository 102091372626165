import { CONFIG } from '@/config';
import { useErrorMessageComposable } from "@/composables/errorTranslationComposable";
import { HttpHeaders, HttpOptions, CapacitorHttp } from '@capacitor/core';

// Define default options:
const BASE_URL = CONFIG.API_HOST + CONFIG.API_URL;
const errorMessageComposable = useErrorMessageComposable();

// Utility functions:
const authHeaders: HttpHeaders = {};
export function setAuthHeaders(token: string): void {
    Object.assign(authHeaders, { 'Authorization': `Token ${token}` });
}

export function removeAuthHeaders(): void {
    delete authHeaders['Authorization'];
}

const contentTypeHeaders: HttpHeaders = {};
export function setContentTypeHeaders() {
    Object.assign(contentTypeHeaders, { 'Content-Type': 'application/json' });
}

export function removeContentTypeHeaders() {
    delete contentTypeHeaders['Content-Type'];
}

// Requests:
setContentTypeHeaders();


export async function convertFileToDataUrl(file: File) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function () {
            resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
    });
}

export async function runFetch(route: string, options: any = {}, hasAuthHeader = false, url = "") {
    // Route check:
    route = url == "" ? `${BASE_URL}${route}` : url;

    if (options.body) {
        // Convert form data to plain object:
        const entries = options.body.entries();
        const json: any = {};
        for (const [key, value] of entries) {
            // Handle files. Property name does not matter in this case.
            if (value instanceof File) {
                json[key] = await convertFileToDataUrl(value);
                continue;
            }

            json[key] = value;
        }
        // Search for files and convert them:
        const jsonString = JSON.stringify(json);
        // add a new `data` property, which is how CapacitorHTTP sends data
        options.data = jsonString;
        delete options.body;
    }

    // Prepare request:
    const httpAuthHeader: HttpHeaders = hasAuthHeader ? authHeaders : { 'Authorization': '' };
    // const httpContentTypeHeader: HttpHeaders = ['PATCH', 'POST'].indexOf(options.method) > -1 ? contentTypeHeaders : {};
    const httpHeaders: HttpHeaders = { ...httpAuthHeader, ...contentTypeHeaders };
    const httpOptions: HttpOptions = Object.assign({ url: route, headers: httpHeaders }, options);
    return request(httpOptions);
}

export async function request(options: HttpOptions) {
    const response = await CapacitorHttp.request(options);

    if (response.status === 204) {
        return;
    }


    if (response.status < 200 || response.status >= 300) {
        const error = await response.data;
        const messages: string[] = Object.values(error);

        errorMessageComposable.toastErrorMessage(messages[0]);

        throw messages;
    }

    return response.data;
}