import { Storage } from '@ionic/storage';
const storage = new Storage();

export function useLocalStorage() {

  return (storage)
}

export default{
  isReady:() => storage.create()
}