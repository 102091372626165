// Capacitor:
import { Capacitor } from '@capacitor/core';
// Vue:
import { RouteRecordRaw } from 'vue-router';
import { createRouter, createWebHistory } from '@ionic/vue-router';
// Storage:
import { useLocalStorage } from "@/composables/localStorage"
// Components:
import TabsRoot from '@/components/TabsRoot.vue';
// Types:
import { ONBOARDING_STATUS, AUTH_TOKEN } from '@/store/storeTypes';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/tabs/home'
  },
  {
    name: 'onboarding',
    path: '/onboarding',
    component: () => import('@/views/Onboarding.vue')
  },
  {
    path: '/resetPassword/:id/:token',
    component: () => import('@/views/securityPanel/ResetPassword.vue')
  },
  {
    name: 'ActivateAccount',
    path: '/activateAccount/:email?',
    component: () => import('@/views/securityPanel/ActivateAccount.vue')
  },
  {
    name: 'privacy',
    path: '/privacy',
    component: () => import('../views/Privacy.vue')
  },
  {
    name: 'contact',
    path: '/contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/tabs/',
    component: TabsRoot,
    children: [
      {
        path: '',
        redirect: 'home'
      },
      {
        name: 'home',
        path: 'home',
        meta: { requiresOnboarding: Capacitor.isNativePlatform() }, // show onboarding only for mobile devies
        component: () => import('@/views/homePanel/Home.vue')
      },
      {
        name: 'favourites',
        path: 'favourites',
        meta: { requiresAuth: true },
        component: () => import('@/views/favouritesPanel/Favourites.vue')
      },
      {
        name: 'register',
        path: '/register',
        component: () => import('@/views/Register.vue')
      },
      {
        name: 'login',
        path: '/login/:id?/:token?',
        component: () => import('@/views/Login.vue'),
      },
      {
        name: 'details',
        path: 'details/:id',
        component: () => import('@/views/detailsPanel/ContragentInfo.vue')
      },
      {
        name: 'userProfile',
        path: 'userProfile',
        meta: { requiresAuth: true },
        component: () => import('@/views/userPanel/UserProfile.vue')
      },
      {
        name: 'messages',
        path: 'messages',
        meta: { requiresAuth: true },
        component: () => import('../views/messagesPanel/Messages.vue')
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

// Guards: https://router.vuejs.org/guide/advanced/meta.html#route-meta-fields
router.beforeEach(async (to) => {
  const storage = useLocalStorage();
  const isAuthenticated = await storage.get(AUTH_TOKEN);
  const isOnboardingComplete = await storage.get(ONBOARDING_STATUS);

  // Handle login page:
  if (to.name === 'login' && isAuthenticated) {
    return { name: 'userProfile' };
  }

  // Handle auth pages:
  if (to.meta.requiresAuth && !isAuthenticated) {
    return { name: 'login' };
  }

  // Handle onboarding:
  if (to.meta.requiresOnboarding && !isOnboardingComplete) {
    return { name: 'onboarding' };
  }

  // default:
  return true;
});

export default router
