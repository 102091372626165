
// Capacitor:
import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';

// Ionic:
import {
    IonMenu,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonCardTitle,
    IonCardSubtitle,
    IonContent,
    IonImg,
    IonList,
    IonSelect,
    IonSelectOption,
    IonItem,
    IonAvatar,
    IonIcon,
    IonLabel,
    IonSplitPane,
    IonButton,
    IonHeader,
    IonToolbar,
} from "@ionic/vue";
import { useIonRouter } from '@ionic/vue';
import {
    personCircleOutline,
    sunnyOutline,
    moonOutline,
} from "ionicons/icons";

// Vue:
import i18n from '@/i18n';
import { useStore } from "vuex";
import { useI18n } from 'vue-i18n'
import { defineComponent, computed, ref, onMounted } from "vue";

// Composables:
import { handleErrors } from '@/composables/handleErrors';
import { useLocalStorage } from "@/composables/localStorage";
import { handleUserDetails } from '@/composables/handleUserDetails';


// Types:
import { LOGOUT, CHANGE_USER_THEME, SET_USER_THEME } from "@/store/storeTypes";
import { ICommonState } from "@/types";

export default defineComponent({
    name: "app-side-menu",
    components: {
        IonMenu,
        IonCard,
        IonCardHeader,
        IonCardContent,
        IonCardTitle,
        IonCardSubtitle,
        IonContent,
        IonImg,
        IonList,
        IonSelect,
        IonSelectOption,
        IonAvatar,
        IonIcon,
        IonItem,
        IonLabel,
        IonButton,
        IonSplitPane,
        IonHeader,
        IonToolbar,
    },
    setup() {
        const store = useStore();
        const localSorage = useLocalStorage();
        const router = useIonRouter();
        const { t, locale } = useI18n();
        const { openToast } = handleErrors();

        const menuRef = ref<typeof IonMenu | null>(null);
        const isLoggedIn = computed(() => store.getters.isAuthenticated);
        const user = computed(() => store.getters.getCurrentUser);
        const menuItems = [
            {
                details: {
                    name: 'TermsOfUse',
                    url: '/privacy'
                }
            },
            {
                details: {
                    name: 'Contact',
                    url: '/contact'
                }
            },
        ];

        const userLogout = async () => {
            await store.dispatch(LOGOUT);

            router.replace({ path: '/login' })
            menuRef.value?.$el.close();
            openToast(t('LogoutSuccessful'), 'success');
        };

        const changeLanguage = async (test: any) => {
            i18n.global.locale.value = test.detail.value
            await localSorage.set('language', i18n.global.locale.value);
        };

        const openPage = (url: string) => {
            router.push({ path: url });
            menuRef.value?.$el.close();
        };

        // Initial set of theme icon:
        const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
        const currentThemeIcon = ref(prefersDark.matches ? moonOutline : sunnyOutline);
        const currentThemeLabel = ref(prefersDark.matches ? 'Dark' : 'Light');

        // Change theme icon based on the setting set by user:
        const changeTheme = (selectedTheme: any): void => {
            const theme = selectedTheme.detail.value;

            // Set theme:
            const isDarkTheme = theme === 'dark';
            document.body.classList.toggle('dark', isDarkTheme);
            currentThemeLabel.value = isDarkTheme ? 'Dark' : 'Light';
            currentThemeIcon.value = isDarkTheme ? moonOutline : sunnyOutline;

            // Set status bar color
            if (Capacitor.getPlatform() === 'ios') {
                StatusBar.setStyle({ style: isDarkTheme ? Style.Dark : Style.Light });
            }

            // Save to store:
            store.dispatch(CHANGE_USER_THEME, theme);
        }

        // Event listener that enable system theme to be selected and applied:
        prefersDark.addEventListener('change', (mediaQuery: any) => {
            currentThemeIcon.value = mediaQuery.matches ? moonOutline : sunnyOutline;
            currentThemeLabel.value = mediaQuery.matches ? 'Dark' : 'Light';
        });

        // Initial set of theme:
        onMounted(async () => {
            const themeFromStorage: ICommonState['theme'] = await localSorage.get('theme');
            store.commit(SET_USER_THEME, themeFromStorage);

            if (themeFromStorage) {
                changeTheme({ detail: { value: themeFromStorage } });
                return;
            }

            const themeFromSettings: ICommonState['theme'] = prefersDark.matches ? 'dark' : 'light';
            store.commit(SET_USER_THEME, themeFromSettings);

            changeTheme({ detail: { value: themeFromSettings } });
        });

        const pushToUserProfile = () => {
            router.push({ path: `/tabs/userProfile` });
        }

        const handleLogInClick = () => {
            router.replace('/login');
            menuRef.value?.$el.close();
        }

        const { getName } = handleUserDetails();
        return {
            router,
            user,
            locale,
            menuRef,
            menuItems,
            isLoggedIn,
            currentThemeIcon,
            currentThemeLabel,
            personCircleOutline,
            t,
            getName,
            openPage,
            userLogout,
            changeTheme,
            changeLanguage,
            handleLogInClick,
            pushToUserProfile,
        };
    },
});
