import { createStore } from 'vuex';
import authModule from './authModule';
import chatModule from './chatModule';
import common from './common';

const store = createStore({
    modules: {
        auth: authModule,
        chat: chatModule,
        common: common
    }
});

export default store;